// FUNCTIONS
// Query Selector
let qs = function (el){
    return document.querySelector(el);
};

// Query Selector All
let qsa = function (el){
    return document.querySelectorAll(el);
};

// Cookies
let getCookie = function (name) {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
};

//Get siblings
let getSiblings = function (el) {
    let siblings = [],
        sibling = el.parentNode.firstChild;
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== el) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling
    }
    return siblings;
};

// Create mobile menu
let mobileMenuCreate = () => {
    let mobMenu = document.createElement('div'),
        mobMenuWrapper = document.createElement('div'),
        menuCopy = qs('header nav ul.menu').cloneNode(true),
        lang = qs('#top .lang-wrapper').cloneNode(true),
        contact = qs('#top .top-contact').cloneNode(true),
        donate = qs('#top .top-donate a').cloneNode(true),
        head = `<div class="head"><div class="logo"></div><div class="close"><i class="icon-cross"></i></div></div>`,
        bottom = `<div class="bottom"><div class="lang-select"></div><div class="contact"></div><div class="donate"></div></div>`;
    mobMenu.setAttribute('id', 'mobile-menu');
    mobMenu.classList.add('closed');
    mobMenuWrapper.classList.add('mobile-menu-wrapper');
    mobMenuWrapper.innerHTML = head;
    mobMenuWrapper.appendChild(menuCopy);
    mobMenuWrapper.innerHTML += bottom;
    mobMenu.appendChild(mobMenuWrapper);
    document.body.appendChild(mobMenu);
    qs('#mobile-menu .bottom .lang-select').appendChild(lang);
    qs('#mobile-menu .bottom .contact').appendChild(contact);
    qs('#mobile-menu .bottom .donate').appendChild(donate);

    //Open/Close subcategories
    qsa('#mobile-menu .dropdown .title').forEach( el => {
        el.addEventListener('click', function(e){
            e.preventDefault();
            let parent = el.closest('.dropdown');
            if(!parent.classList.contains('open')){
                parent.classList.add('open');
            }
            else{
                parent.classList.remove('open');
            }

        })
    });

    //Close mobile menu
    qs('#mobile-menu .close').addEventListener('click', () => {
        qs('#mobile-menu').classList.add('closed');
        qsa('#mobile-menu .dropdown').forEach( el => {
            el.classList.remove('open');
        });
        document.body.classList.remove('overflow');
    });
}

let startShowMobileMenu = () => {
    qs('header').classList.add('check-width','desktop');
    let container = qs('header nav').clientWidth,
        menu = qs('header nav .menu').clientWidth;
    if(container >= menu){
        qs('header').classList.add('desktop');
        qs('#mobile-menu').classList.add('desktop');
        document.body.classList.remove('overflow');
        qs('header').classList.remove('check-width');
    }
    else {
        qs('header').classList.remove('desktop', 'check-width');
        qs('#mobile-menu').classList.remove('desktop');
    }
}
//Create cookies message
let cookiesMessage = function(){
    if (getCookie('cookiesMessage') !== 'true'){
        let container = document.createElement('div'),
        doc = qs('html'),
        lang = doc.getAttribute('lang'),
        message = '',
        message_en = `<i class="icon-cross"></i><h3>We use cookies</h3><p>Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies. <a href="#" class="line">Find out how we use cookies</a>.</p> <button class="btn primary ok">Ok</button>`,
        message_uk = `<i class="icon-cross"></i><h3>Ми використовуємо файли cookie</h3><p>Файли cookie допомагають нам забезпечити найкращий досвід роботи на нашому веб-сайті. Користуючись нашим веб-сайтом, ви погоджуєтеся на використання файлів cookie. <a href="#" class="line">Дізнайтеся, як ми використовуємо файли cookie</a>.</p> <button class="btn primary ok">Добре</button>`,
        message_ru = `<i class="icon-cross"></i><h3>Мы используем файлы cookie</h3><p>Файлы cookie помогают нам обеспечить наилучшее взаимодействие с нашим веб-сайтом. Используя наш веб-сайт, вы соглашаетесь на использование файлов cookie. <a href="#" class="line">Узнайте, как мы используем файлы cookie</a>.</p> <button class="btn primary ok">Хорошо</button>`,
        message_ro = `<i class="icon-cross"></i><h3>Folosim module cookie</h3><p>Cookie-urile ne ajută să oferim cea mai bună experiență pe site-ul nostru. Prin utilizarea site-ului nostru, sunteți de acord cu utilizarea cookie-urilor. <a href="#" class="line">Aflați cum folosim cookie-urile</a>.</p> <button class="btn primary ok">De acord</button>`;
        switch (lang){
            case 'ru':
                message = message_ru;
                break;
            case 'ro':
                message = message_ro;
                break;
            case 'uk':
                message = message_uk;
                break;
            case 'en':
                message = message_en;
                break;
            default:
                message = message_ro;
                break;
        }
        container.setAttribute('id', 'cookies-message');
        container.innerHTML = message;
        document.body.appendChild(container);

        // Set handlers
        qs('#cookies-message button.ok').addEventListener('click', () => {
            document.cookie = 'cookiesMessage=true; max-age=15552000'
            qs('#cookies-message').classList.add('hide');
        });
        qs('#cookies-message .icon-cross').addEventListener('click', () => {
            qs('#cookies-message').classList.add('hide');
        });
    }
};

// Hero badges
let createHeroBadges = () => {
    if(document.body.id === 'index'){
        let block = qs('#hero .badges'),
            str = block.innerHTML,
            block_array = str.split(",");
        block.innerHTML = '';
        block_array.forEach(el => {
            let badge = document.createElement('span');
            badge.classList.add('badge');
            badge.innerHTML = el;
            block.appendChild(badge);
        });
    }
}

// ELEMENT HANDLERS

// Open/Close SEO description block
qsa('.show-all').forEach( el => {
    el.addEventListener('click', function () {
        let parent = el.closest('.description');
        if(!parent.classList.contains('full')){
            parent.classList.add('full');
        }
        else{
            parent.classList.remove('full');
        }

    });
});

// Open mobile menu
qs('header .mobile-nav-btn').addEventListener('click', () => {
    qs('#mobile-menu').classList.remove('closed');
    document.body.classList.add('overflow');
});

// Accordion
qsa('.accordion .accordion-item__title').forEach(el => {
   el.addEventListener('click', function(){
       let item = el.parentElement,
           showOnlyOne = el.closest('.accordion').classList.contains('show-only-one');
       if(!item.classList.contains('open')){
           item.classList.add('open');
           if(showOnlyOne){
               let itemSiblings = getSiblings(item);
               itemSiblings.forEach(el => {
                   el.classList.remove('open');
               });
           }
       }
       else{
           item.classList.remove('open');
       }
   });
});

// Footer accordion for desktops
qsa('footer .accordion .accordion-item__title').forEach(el => {
    el.addEventListener('click', function (e){
        if(window.innerWidth >= 1200){
            e.preventDefault();
        }
    })
});

// POPUP

// --open
qsa('.popup-open').forEach(el => {
    el.addEventListener('click', () => {
        let popupName = el.dataset.popup;
        qs('#'+popupName).classList.add('show');
        document.body.classList.add('overflow');
    });
});
// --close on close btn
qsa('.popup .close').forEach(el => {
   el.addEventListener('click', () => {
      el.closest('.popup').classList.remove('show');
       document.body.classList.remove('overflow');
   });
});
// --close on overlay click
qsa('.popup').forEach(el => {
   el.addEventListener('click', () => {
      el.classList.remove('show');
       document.body.classList.remove('overflow');
   });
});
//-- prevent close on content zone clicking
qsa('.popup-content').forEach(el => {
    el.addEventListener('click', function (e) {
        e.stopPropagation();
    });
});
// --close popup and clear form
qsa('form .cancel').forEach(el => {
   el.addEventListener('click', () => {
      el.closest('.popup').classList.remove('show');
      el.closest('form').reset();
       document.body.classList.remove('overflow');
   });
});

// Check phone inputs
qsa('input[type="tel"]').forEach(el => {
    el.addEventListener('input', (event) => {
        const inputChar = event.data,
              allowedRegex = /^[\d\s\+]+$/;
        let parent = el.closest('label');
        if (!allowedRegex.test(inputChar)) {
            const isDeleteOrBackspace = event.inputType === "deleteContentBackward" || event.inputType === "deleteContentForward";
            if (!isDeleteOrBackspace) {
                parent.classList.add("error");
                el.value = el.value.replace(inputChar, "");
            }
        }
        else{
            if(parent.classList.contains('error')){
                parent.classList.remove('error');
            }
        }
    });
});

// READY
let readyPage = () => {
    mobileMenuCreate();
    cookiesMessage();
    startShowMobileMenu();
    createHeroBadges();
}

// RESIZE
let resizePage = () => {
    startShowMobileMenu();
}

// WINDOW EVENT LISTENERS
window.addEventListener('load', readyPage, false);
window.addEventListener('resize', resizePage, false);
window.addEventListener('orientationchange', resizePage, false);

// FORMS
document.addEventListener('fetchit:before', (e) => {
    const { form, response } = e.detail;
    if (form.getAttribute('id') === 'get-help-form') {
        let popup = document.querySelector('.popup#get-help'),
            phoneField = form.querySelector('input[type="tel"]');
        if(phoneField.value === ''){
            e.preventDefault();
            phoneField.focus();
            phoneField.closest('label').classList.add('error');
        }
        else popup.classList.add('sending');
    }
})
document.addEventListener('fetchit:success', (e) => {
    const { form, response } = e.detail;
    if (form.getAttribute('id') === 'get-help-form') {
        let popup = document.querySelector('.popup#get-help');
        popup.classList.add('done');
        setTimeout(function (){
            popup.classList.remove('show','sending','done');
            popup.querySelector('#get-help-form').reset();
            document.body.classList.remove('overflow');
        },5000);

    }
});
document.addEventListener('fetchit:error', (e) => {
    const { form, response } = e.detail;
    if (form.getAttribute('id') === 'get-help-form') {
        let popup = document.querySelector('.popup#get-help');
            popup.classList.remove('sending','done');
    }
});